import { useCallback, useState, useEffect } from 'react';

export const useSetState = initState => {
  const [state, setState] = useState(initState);

  const setMergeState = useCallback(patch => {
    setState(prevState => {
      const newState = typeof patch === 'function' ? patch(prevState) : patch;
      return newState ? { ...prevState, ...newState } : prevState;
    });
  }, []);

  return [state, setMergeState];
};

/**
 * A custom hook that returns whether a media query matches the current screen
 * Supports SSR and handles browser compatibility
 * @param {string} query - The media query to check
 * @returns {boolean} - Whether the media query matches
 */

export const useMediaQuery = query => {
  // Check if we're in a browser environment
  const getMatches = query => {
    // Return false on the server
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState(getMatches(query));

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const mediaQuery = window.matchMedia(query);

      // Handle initial value
      setMatches(mediaQuery.matches);

      // Create event handler
      const handler = event => setMatches(event.matches);

      // Add event listener with fallback for older browsers
      if (mediaQuery.addEventListener) {
        mediaQuery.addEventListener('change', handler);
      } else {
        // Legacy support
        mediaQuery.addListener(handler);
      }

      // Cleanup function
      return () => {
        if (mediaQuery.removeEventListener) {
          mediaQuery.removeEventListener('change', handler);
        } else {
          // Legacy support
          mediaQuery.removeListener(handler);
        }
      };
    }
  }, [query]);

  return matches;
};

/**
 * Custom hook specifically for checking if device is mobile
 * @returns boolean indicating if the device is mobile */
export const useIsMobile = () => useMediaQuery(`(max-width: 960px)`);
