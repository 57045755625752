import styled from 'styled-components';
import {
  BREAKPOINT,
  MARGIN,
  FONT_WEIGHT,
  FONT_FAMILY,
  COLOR
} from '@latitude/core/utils/constants';
import LoanCard from './LoanCard';

const LoanCardBranded = styled(LoanCard)`
  font-weight: ${FONT_WEIGHT.LIGHT};

  .loan-card__icon {
    width: 96px;
    height: 96px;
    margin: 0 auto 1rem;
    display: block;
  }

  .loan-card__title {
    font-size: 24px;
    line-height: 32px;
    font-family: ${FONT_FAMILY.TITLE};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    color: ${COLOR.BLACK};
  }

  .loan-card__description {
    font-size: 16px;
    font-weight: ${FONT_WEIGHT.LIGHT};
    color: ${COLOR.GREY75};
  }

  ul {
    margin-bottom: ${MARGIN.M32};

    @media (min-width: ${BREAKPOINT.LG}) {
      margin-bottom: ${MARGIN.M24};
    }

    li {
      color: ${COLOR.GREY75};
      position: relative;

      ::before {
        content: '•';
        font-size: 36px;
        color: ${COLOR.BLUE_LIGHT};
      }
    }
  }
`;

export default LoanCardBranded;
