import React from 'react';
import classnames from 'classnames';
import SvgInline from '@latitude/svg-inline';
import { RatesBox } from '@latitude/rates-box';
import ReactHTMLParser from 'react-html-parser';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import PropTypes from 'prop-types';
import ratesData from '../../data/json/Rates/rates.json';
import './_loan-card.scss';

const LoanCard = props => (
  <div className={classnames('loan-card', props.className)}>
    <div className="loan-card__header">
      {props.icon && (
        <SvgInline name={props.icon} className="loan-card__icon" />
      )}

      <h3 className="loan-card__title">{props.title}</h3>
      {props.description && (
        <p className="loan-card__description">{props.description}</p>
      )}
    </div>
    <div className="loan-card__rates">
      {props.rateBoxType && (
        <RatesBox
          isBranded={props.isBranded}
          type={props.rateBoxType}
          size={props.rateBoxSize || 'small'}
          fullWidth={props.rateBoxFullWidth}
          css="margin-bottom: 20px;"
          data={ratesData}
        />
      )}
      {props.rateBoxType2 && (
        <RatesBox
          isBranded={props.isBranded}
          theme="secondary"
          type={props.rateBoxType2}
          size={props.rateBoxSize || 'small'}
          fullWidth={props.rateBoxFullWidth}
          css="margin-bottom:20px"
          data={ratesData}
        />
      )}
    </div>
    {props.listItems1Title && props.listItems1Title}
    {props.listItems1 && (
      <ul className="loan-card__list">
        {props.listItems1.map((item, i) => (
          <li key={i} className="loan-card__list-item">
            {ReactHTMLParser(item)}
          </li>
        ))}
      </ul>
    )}
    {props.listItems2Title && props.listItems2Title}
    {props.listItems2 && (
      <ul className="loan-card__list">
        {props.listItems2.map((item, i) => (
          <li key={i} className="loan-card__list-item">
            {ReactHTMLParser(item)}
          </li>
        ))}
      </ul>
    )}
    {props.listItems3Title && props.listItems3Title}
    {props.listItems3 && (
      <ul className="loan-card__list">
        {props.listItems3.map((item, i) => (
          <li key={i} className="loan-card__list-item">
            {ReactHTMLParser(item)}
          </li>
        ))}
      </ul>
    )}
    <div className="loan-card__button-container">
      {props.button2 && (
        <Link
          button={BUTTON_STYLE.PRIMARY}
          {...props.button2}
          className={classnames(
            'loan-card__button button--primary',
            props.button2.className
          )}
        >
          {props.button2.text}
        </Link>
      )}
      {props.button1 && (
        <Link
          button={BUTTON_STYLE.TERTIARY}
          {...props.button1}
          className={classnames('loan-card__button', props.button1.className)}
        >
          {props.button1.text}
        </Link>
      )}
    </div>
  </div>
);

/* eslint-disable react/forbid-prop-types */
LoanCard.propTypes = {
  title: PropTypes.node,
  description: PropTypes.string,
  rateBoxType: PropTypes.string,
  rateBoxType2: PropTypes.string,
  listItems: PropTypes.array,
  button1: PropTypes.object,
  button2: PropTypes.object
};

export default LoanCard;
